import sanitizeString from './sanitize-string.helper.js';
import slugify from './slugify.helper.js';

export default (search, page = 1, year, nbPerPage) => {
  if (search.type === 'online') {
    return `/${search.type}${ year ? `/${year}`: ''}${page && page > 1 ? `/${page}` : ''}`;
  }

  let searchTerm = search.searchTerm;
  if (search.type === 'bo') {
    return `/bo/events?${searchTerm ? `&s=${searchTerm}` : ''}` +
      `${ year ? `&y=${year}`: ''}` +
      `${page && page > 1 ? `&p=${page}` : ''}` +
      `${nbPerPage ? `&l=${nbPerPage}` : ''}` +
      `${search.showDeleted ? '&d=true' : ''}`;
  }
  else if (search.type === 'search') {
    return `/${search.type}${ year ? `/${year}`: ''}${page && page > 1 ? `/${page}` : ''}?s=${searchTerm}`;
  }
  else if (search.type === 'city') {
    if (search.searchTerm?.city) searchTerm = `${slugify(search.searchTerm.country)}/${slugify(search.searchTerm.city)}`;
    else searchTerm = `${slugify(search.country)}/${slugify(search.city)}`
  }
  else if (search.type === 'country' || search.type === 'theme') searchTerm = sanitizeString(search.searchTerm);
  else {
    if (Array.isArray(search.searchTerm)) searchTerm = search.searchTerm[0].toLowerCase();
    else searchTerm = search.searchTerm.toLowerCase();
  }

  return `/${search.type}/${searchTerm}${ year ? `/${year}`: ''}${page && page > 1 ? `/${page}` : ''}`;
}
