export default (str, encode = false, spaceFiler = '-') => {
  if (!str) return '';
  // console.log(str);

  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_—';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz---';
  const p = new RegExp(a.split('').join('|'), 'g');

  let safeSlug = str.toString().toLowerCase()
    .trim()
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, 'and') // Replace & with 'and'
    .replace(/[^[a-zA-Z0-9أ-ي]-]+/g, '') // Arabic support
    .replace(/["'§@(){}\[\]#$*€^`£%°¨?!.:;,+=*$£€¥¨^°¶§“‘«¡®‚†™ºªπ∏‡ΩÒ∑∆ﬁﬂ¬|µ‰≤≥<>‹›≈⁄©¢◊√~ı∞¿…•÷≠±]/gm, '') // Remove special characters
    .replace(/\//g, '-')
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
  
  if (!encode) safeSlug = safeSlug.replace(/\s+/g, spaceFiler) // Replace spaces with '-' or specified character
  else safeSlug = safeSlug.replace(/\s+/g, encodeURI(' ')) // Replace spaces with -

  return safeSlug;
}
